export default async ({ store }) => {
  if (store.getters.isRoleCoMedical) {
    await Promise.all([
      store.dispatch('notification/updateUnreadCountIfNeeded').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      }),
      store
        .dispatch('hospitalNotice/getHospitalNoticeUnreadCount')
        .catch(() => {
          // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
        })
    ])
    return
  }
  if (store.getters.isRoleDoctor) {
    await Promise.all([
      store.dispatch('fetchUnreadCounts').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      }),
      store.dispatch('notification/updateUnreadCountIfNeeded').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      }),
      store.dispatch('chat/updateUnreadCountIfNeeded').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      })
    ])
    return
  }
  if (store.getters.isRoleMs) {
    await Promise.all([
      store.dispatch('fetchUnreadCounts').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      }),
      store.dispatch('notification/updateUnreadCountIfNeeded').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      }),
      store.dispatch('chat/updateUnreadCountIfNeeded').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      })
    ])
  }
  if (store.getters.isRoleConnect) {
    await Promise.all([
      store.dispatch('fetchUnreadCounts').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      }),
      store.dispatch('notification/updateUnreadCountIfNeeded').catch(() => {
        // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
      })
    ])
  }
}
