export const state = () => ({
  isDocsiruConnect: false,
  firstName: '',
  lastName: '',
  job: '',
  jobAdditionalText: '',
  prefectureCode: '',
  prefectureName: '',
  hospitalName: '',
  hospitalAddress: '',
  email: '',
  body: '',
  step: 'input',
  companyCode: '',
  isConfirmed: false,
  inquiryMailAddress: ''
})

export const getters = {
  jobList(state) {
    return state.isDocsiruConnect
      ? [
          { text: '医師', value: 1 },
          { text: '歯科医師', value: 2 },
          { text: '看護師、准看護師', value: 3 },
          { text: '薬剤師', value: 4 },
          { text: '保健師', value: 5 },
          { text: '助産師', value: 6 },
          { text: '診療放射線技師、診療エックス線技師', value: 7 },
          { text: '臨床検査技師、衛生検査技師', value: 8 },
          { text: '柔道整復師', value: 9 },
          { text: 'はり師・きゅう師', value: 10 },
          { text: 'あんまマッサージ指圧師', value: 11 },
          { text: '整体師', value: 12 },
          { text: '医療情報管理士', value: 13 },
          { text: '診療情報管理士', value: 14 },
          { text: '理学療法士', value: 15 },
          { text: '作業療法士', value: 16 },
          { text: '視能訓練士', value: 17 },
          { text: '言語聴覚士', value: 18 },
          { text: '義肢装具士', value: 19 },
          { text: '臨床工学技士', value: 20 },
          { text: '管理栄養士', value: 21 },
          { text: 'スポーツ栄養士', value: 22 },
          { text: '栄養士', value: 23 },
          { text: '音楽療法士', value: 24 },
          { text: '臨床心理士', value: 25 },
          { text: '救急救命士', value: 26 },
          { text: '歯科衛生士', value: 27 },
          { text: '歯科技工士', value: 28 },
          { text: '社会福祉士', value: 29 },
          { text: '医療事務', value: 30 },
          { text: '医療ソーシャルワーカー', value: 31 },
          { text: '医療秘書', value: 32 },
          { text: 'カウンセラー', value: 33 },
          { text: '病棟クラーク', value: 34 },
          { text: '医学生', value: 35 },
          { text: '歯学生', value: 36 },
          { text: '薬学生', value: 37 },
          { text: '看護学生', value: 38 },
          {
            text: 'その他医療従事者、研究教育者',
            value: 39,
            requiredAdditionalText: true
          },
          { text: '製薬企業勤務者（MR）', value: 40 },
          { text: '製薬企業勤務者（本社　営業・マーケティング）', value: 41 },
          { text: '製薬企業勤務者（本社　研究開発）', value: 42 },
          { text: '製薬企業勤務者（その他）', value: 43 },
          {
            text: 'その他医療関連企業勤務者',
            value: 44,
            requiredAdditionalText: true
          }
        ]
      : [
          { text: '医師', value: 1 },
          { text: '薬剤師', value: 2 },
          { text: '看護師', value: 3 },
          { text: '医療ソーシャルワーカー', value: 4 },
          { text: '事務職', value: 5 },
          { text: 'その他', value: 6 }
        ]
  },
  formErrorMessage(state, getters) {
    const errorMessages = []
    if (!state.lastName) errorMessages.push('姓を入力してください。')
    if (!state.firstName) errorMessages.push('名を入力してください。')
    if (!state.job) errorMessages.push('職種を選択してください。')
    if (state.isDocsiruConnect) {
      if (getters.isShowJobAdditionalTextInput && !state.jobAdditionalText) {
        errorMessages.push('職種を入力してください。')
      }
    }
    if (!state.prefectureCode)
      errorMessages.push('都道府県を選択してください。')
    if (!state.hospitalName)
      errorMessages.push(`所属${getters.orgName}名を入力してください。`)
    if (!state.hospitalAddress)
      errorMessages.push(`所属${getters.orgName}の所在地を入力してください。`)
    if (!state.email) errorMessages.push('メールアドレスを入力してください。')
    if (!state.body) errorMessages.push('お問い合わせ内容を入力してください。')
    if (!state.isConfirmed)
      errorMessages.push('利用規約及びプライバシーポリシーに同意してください。')
    if (!state.companyCode)
      errorMessages.push('ご希望のお問い合わせ先会社を選択してください。')
    return errorMessages.join('\n')
  },
  serviceName(state) {
    return state.isDocsiruConnect ? 'ドクシルコネクト' : 'ドクシル'
  },
  orgName(state) {
    return state.isDocsiruConnect ? '組織' : '医療機関'
  },
  isShowJobAdditionalTextInput(state, getters) {
    return getters.jobList.find(job => job.value === state.job)
      ?.requiredAdditionalText
  }
}

export const mutations = {
  isDocsiruConnect(state, value) {
    state.isDocsiruConnect = value
  },
  firstName(state, value) {
    state.firstName = value
  },
  lastName(state, value) {
    state.lastName = value
  },
  job(state, value) {
    state.job = value
  },
  jobAdditionalText(state, value) {
    state.jobAdditionalText = value
  },
  setPrefectureCode(state, value) {
    state.prefectureCode = value
  },
  setPrefectureName(state, payload) {
    state.prefectureName = payload
  },
  setInquiryMailAddress(state, payload) {
    state.inquiryMailAddress = payload
  },
  hospitalName(state, value) {
    state.hospitalName = value
  },
  hospitalAddress(state, value) {
    state.hospitalAddress = value
  },
  email(state, value) {
    state.email = value
  },
  body(state, value) {
    state.body = value
  },
  setCompanyCode(state, value) {
    state.companyCode = value
  },
  setIsConfirmed(state, value) {
    state.isConfirmed = value
  },
  step(state, value) {
    state.step = value
  }
}

export const actions = {
  async send_contact({ state, getters }) {
    const jobText = getters.jobList[state.job - 1].text
    await this.$axios.$post('/api/contact', {
      firstName: state.firstName,
      lastName: state.lastName,
      job: getters.isShowJobAdditionalTextInput
        ? `${jobText}（${state.jobAdditionalText}）`
        : jobText,
      hospitalName: state.hospitalName,
      prefectureName: state.prefectureName,
      hospitalAddress: state.hospitalAddress,
      email: state.email,
      body: state.body,
      companyCode: state.companyCode,
      isConfirmed: state.isConfirmed,
      isDocsiruConnect: state.isDocsiruConnect
    })
  }
}
