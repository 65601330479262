import { getTopPath } from '../helper/role'
import constant from '~/helper/constant'

export default ({ store, redirect, route, app }) => {
  if (
    !store.state.currentUser ||
    store.state.currentUser.role !== constant.roles.connect
  ) {
    redirect(getTopPath(store.state.currentUser.role))
    app.$cookies.set(config.redirectKey, route.fullPath)
  }
}
