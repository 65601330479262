<template>
  <footer>
    <div>
      <div class="footerItem">
        <a target="_blank" href="/terms">利用規約</a>
      </div>
      <div class="footerItem">
        <a
          target="_blank"
          href="https://gekkaworks.co.jp/privacy"
          rel="noopener"
          >プライバシーポリシー</a
        >
      </div>
      <div class="footerItem">
        <a href="/contact/form?connect=true" target="_blank">お問い合わせ</a>
      </div>
      <div class="footerItem">
        <a target="_blank" rel="noopener" :href="aboutUrl">運営会社</a>
      </div>
    </div>
    <div class="copy">©2022 GEKKA WORKS Co.,Ltd.</div>
  </footer>
</template>

<script>
import constant from '~/helper/constant'
export default {
  computed: {
    aboutUrl: () => constant.url.about
  }
}
</script>

<style lang="scss" scoped>
footer {
  width: 100vw;
  padding: 32px 24px 24px 24px;
  color: #fff;
  background: $primary-color;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.footerItem {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  &:last-child {
    margin-bottom: 0;
  }
}
.copy {
  margin-top: 48px;
  font-size: 10px;
  line-height: 1.6;
}
.footerItem a {
  color: #fff;
  text-decoration: none;
}
</style>
