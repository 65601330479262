const middleware = {}

middleware['accessOnlyCoMedical'] = require('../middleware/accessOnlyCoMedical.js')
middleware['accessOnlyCoMedical'] = middleware['accessOnlyCoMedical'].default || middleware['accessOnlyCoMedical']

middleware['accessOnlyCommunityUser'] = require('../middleware/accessOnlyCommunityUser.js')
middleware['accessOnlyCommunityUser'] = middleware['accessOnlyCommunityUser'].default || middleware['accessOnlyCommunityUser']

middleware['accessOnlyConnect'] = require('../middleware/accessOnlyConnect.js')
middleware['accessOnlyConnect'] = middleware['accessOnlyConnect'].default || middleware['accessOnlyConnect']

middleware['accessOnlyDoctor'] = require('../middleware/accessOnlyDoctor.js')
middleware['accessOnlyDoctor'] = middleware['accessOnlyDoctor'].default || middleware['accessOnlyDoctor']

middleware['accessOnlyMS'] = require('../middleware/accessOnlyMS.js')
middleware['accessOnlyMS'] = middleware['accessOnlyMS'].default || middleware['accessOnlyMS']

middleware['accessOnlyMSandDoctor'] = require('../middleware/accessOnlyMSandDoctor.js')
middleware['accessOnlyMSandDoctor'] = middleware['accessOnlyMSandDoctor'].default || middleware['accessOnlyMSandDoctor']

middleware['authCheck'] = require('../middleware/authCheck.ts')
middleware['authCheck'] = middleware['authCheck'].default || middleware['authCheck']

middleware['authCheckConnect'] = require('../middleware/authCheckConnect.ts')
middleware['authCheckConnect'] = middleware['authCheckConnect'].default || middleware['authCheckConnect']

middleware['checkTerms'] = require('../middleware/checkTerms.js')
middleware['checkTerms'] = middleware['checkTerms'].default || middleware['checkTerms']

middleware['clearRefreshInterval'] = require('../middleware/clearRefreshInterval.js')
middleware['clearRefreshInterval'] = middleware['clearRefreshInterval'].default || middleware['clearRefreshInterval']

middleware['fetchFrontendUserStorage'] = require('../middleware/fetchFrontendUserStorage.js')
middleware['fetchFrontendUserStorage'] = middleware['fetchFrontendUserStorage'].default || middleware['fetchFrontendUserStorage']

middleware['getCompanyList'] = require('../middleware/getCompanyList.js')
middleware['getCompanyList'] = middleware['getCompanyList'].default || middleware['getCompanyList']

middleware['getMypageUnreadCount'] = require('../middleware/getMypageUnreadCount.js')
middleware['getMypageUnreadCount'] = middleware['getMypageUnreadCount'].default || middleware['getMypageUnreadCount']

middleware['getNotificationCount'] = require('../middleware/getNotificationCount.js')
middleware['getNotificationCount'] = middleware['getNotificationCount'].default || middleware['getNotificationCount']

middleware['getPrefectureCompanyList'] = require('../middleware/getPrefectureCompanyList.js')
middleware['getPrefectureCompanyList'] = middleware['getPrefectureCompanyList'].default || middleware['getPrefectureCompanyList']

middleware['getProfile'] = require('../middleware/getProfile.js')
middleware['getProfile'] = middleware['getProfile'].default || middleware['getProfile']

middleware['getUnreadBusinessCardCount'] = require('../middleware/getUnreadBusinessCardCount.js')
middleware['getUnreadBusinessCardCount'] = middleware['getUnreadBusinessCardCount'].default || middleware['getUnreadBusinessCardCount']

middleware['getUnreadBusinessCardRequestCount'] = require('../middleware/getUnreadBusinessCardRequestCount.js')
middleware['getUnreadBusinessCardRequestCount'] = middleware['getUnreadBusinessCardRequestCount'].default || middleware['getUnreadBusinessCardRequestCount']

middleware['getUnreadChatMessageCount'] = require('../middleware/getUnreadChatMessageCount.js')
middleware['getUnreadChatMessageCount'] = middleware['getUnreadChatMessageCount'].default || middleware['getUnreadChatMessageCount']

middleware['getUnreadCounts'] = require('../middleware/getUnreadCounts.js')
middleware['getUnreadCounts'] = middleware['getUnreadCounts'].default || middleware['getUnreadCounts']

middleware['getUnreadHospitalNoticeCount'] = require('../middleware/getUnreadHospitalNoticeCount.js')
middleware['getUnreadHospitalNoticeCount'] = middleware['getUnreadHospitalNoticeCount'].default || middleware['getUnreadHospitalNoticeCount']

middleware['pageRestricted'] = require('../middleware/pageRestricted.js')
middleware['pageRestricted'] = middleware['pageRestricted'].default || middleware['pageRestricted']

middleware['redirectToConnectTopIfLogined'] = require('../middleware/redirectToConnectTopIfLogined.js')
middleware['redirectToConnectTopIfLogined'] = middleware['redirectToConnectTopIfLogined'].default || middleware['redirectToConnectTopIfLogined']

middleware['redirectToRetryRegisterIfNoMail'] = require('../middleware/redirectToRetryRegisterIfNoMail.js')
middleware['redirectToRetryRegisterIfNoMail'] = middleware['redirectToRetryRegisterIfNoMail'].default || middleware['redirectToRetryRegisterIfNoMail']

middleware['redirectToTopIfLogined'] = require('../middleware/redirectToTopIfLogined.js')
middleware['redirectToTopIfLogined'] = middleware['redirectToTopIfLogined'].default || middleware['redirectToTopIfLogined']

export default middleware
