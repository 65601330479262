import HttpRepository from '../httpRepository'

export default class ConnectUserRepository extends HttpRepository {
  fetchProfile(Id: string): Promise<any> {
    return this.get(`/api/connect/getProfileById/${Id}`)
  }

  updateProfile(params: {
    objectId: string
    introduction: string | undefined
    avatarUrl: string | undefined
  }): Promise<any> {
    return this.patch('/api/connect/updateProfileById', params)
  }
}
