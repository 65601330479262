import dayjs from 'dayjs'
import Compressor from 'compressorjs'
import { FORMAT_DATE_DAY, USER_ID_WITH_ROLE_DELIMITER } from '~/helper/enum'
import { Doctor, MS, Territory, Reaction } from '~/types/api'
import { Emoji } from '~/helper/emojiList'

export const toFullWidthNumber = (harfNumber: number): string => {
  return String(harfNumber).replace(/[0-9]/g, input =>
    String.fromCharCode(input.charCodeAt(0) + 65248)
  )
}

export const flattenArr = <T>(arr: T[][]): T[] =>
  (<T[]>[]).concat.apply([], arr)

export const sleepMs = (ms: number) => {
  return new Promise<void>(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export const unique = <T>(input: T[]): T[] => [...new Set(input)]

export const formatDate = (date: string | number, format?: string): string => {
  return dayjs(date).format(format ?? FORMAT_DATE_DAY)
}

export const calculateGeoPointRange = (
  items: {
    id: string
    lat: number
    lng: number
  }[]
): { maxLng: number; maxLat: number; minLng: number; minLat: number } => {
  let maxLat: number = 0
  let maxLng: number = 0
  let minLat: number = 0
  let minLng: number = 0

  items.forEach(({ lat, lng }, index) => {
    if (index === 0) {
      maxLat = lat
      minLat = lat
      maxLng = lng
      minLng = lng
      return
    }

    if (lng > maxLng) {
      maxLng = lng
    }
    if (lng < minLng) {
      minLng = lng
    }
    if (lat > maxLat) {
      maxLat = lat
    }
    if (lat < minLat) {
      minLat = lat
    }
  })

  return { maxLng, maxLat, minLng, minLat }
}

export const generateChatRoomTitle = (
  members: any,
  currentUserId: string
): string => {
  const membersWithoutMe = members.filter(
    (member: any) => member.objectId !== currentUserId
  )
  const maxCount = 4
  let result = membersWithoutMe
    .slice(0, maxCount)
    .map((member: any) => `${member.lastName} ${member.firstName}`)
    .join(', ')
  if (membersWithoutMe.length > maxCount) {
    result += '…'
  }
  if (membersWithoutMe.length > 1) {
    result += ` (${membersWithoutMe.length})`
  }
  return result
}

export const generateEmailUrl = (title: string, body: string): string => {
  return `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(body)}`
}

export const toDataUrl = (file: File): Promise<string | undefined> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader?.result?.toString().split(',')[1])
    }
  })
}

export const generateBase64Image = (file: File): Promise<string> => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise(resolve => {
    reader.onload = () => {
      resolve(reader.result as string)
    }
  })
}

export const compressImage = (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    return new Compressor(file, {
      quality: 0.6,
      success: result => {
        resolve(result)
      },
      error: error => {
        reject(error)
      }
    })
  })
}

export const toArr = <T>(input: T): T[] => {
  if (Array.isArray(input)) return input
  if (!input) return []
  return [input]
}

export const cloneDeep = <T>(input: T | undefined | null): T | undefined => {
  if (input === undefined || input === null) {
    return undefined
  }

  return JSON.parse(JSON.stringify(input))
}

export const escapeHtml = (string: any): string => {
  if (typeof string !== 'string') {
    return string
  }
  return string.replace(/[&'`"<>]/g, function (match): string {
    return (
      {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;'
      }[match] || ''
    )
  })
}

export const checkPasswordFormat = (
  password: string,
  errorMessage: string = 'パスワードの形式が正しくありません'
): string => {
  if (!password) return ''
  if (!/^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$/.test(password)) {
    return errorMessage
  }
  return ''
}

export const checkValidEmailCode = (input: string): string => {
  if (input.match(/[0-9]{4}/)) {
    return ''
  }
  return '認証コードを正しく入力してください'
}

export const containSpecialChars = (
  text: string,
  regexException?: RegExp
): boolean => {
  const newStr = regexException ? text.replaceAll(regexException, '') : text
  const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\w\s\u3000-\u9FFF]|[「」。！／：＠［｀｛～、〜"''・]+/g

  return regex.test(newStr)
}

const containsZenkaku = (string: string): boolean => {
  return !string.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)
}

export const isValidEmail = (email: string): boolean => {
  if (!email) return false
  if (containsZenkaku(email)) return false
  if (email.match(/[ ,]/)) return false
  if (!email.match(/.*@.*\..*/)) return false
  return true
}

// 125432 → 13万
// 13 → 13
export const formatCount = (val: number): string => {
  if (!val) {
    return '0'
  }

  if (val < 10000) {
    return String(val)
  }

  // 千の位で四捨五入
  const countRounded = Math.round(val / 10000) * 10000

  const valStr = String(countRounded)
  if (valStr.length <= 4) {
    return String(val)
  }

  return `約${Number(valStr.slice(0, valStr.length - 4))}万`
}

export const groupByDate = <T extends { createdAt: string }>(
  arr: T[]
): {
  date: string
  items: T[]
}[] => {
  let beforeDate: string
  const results: {
    date: string
    items: T[]
  }[] = []
  arr.forEach(item => {
    const date = dayjs(item.createdAt).format(FORMAT_DATE_DAY)
    if (beforeDate === date) {
      const foundIndex = results.findIndex(result => result?.date === date)
      if (foundIndex !== -1) results[foundIndex]?.items.push(item)
    } else {
      results.push({ date, items: [item] })
    }
    beforeDate = date
  })
  return results
}

export const groupByDateBusinessCard = <T extends { exchangedAt: string }>(
  arr: T[],
  isAscending: boolean = false
): {
  date: string
  items: T[]
}[] => {
  let beforeDate: string
  const results: {
    date: string
    items: T[]
  }[] = []
  arr.forEach(item => {
    const date = dayjs(item.exchangedAt).format('YYYY/M')
    if (beforeDate === date) {
      const foundIndex = results.findIndex(result => result?.date === date)
      if (foundIndex !== -1) results[foundIndex]?.items.push(item)
    } else {
      results.push({ date, items: [item] })
    }
    beforeDate = date
  })
  return isAscending
    ? results
    : results.sort((a, b) => {
        return dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1
      })
}

export const sortCommunityOrder = <
  T extends {
    latestAt?: string
    isAdmin: boolean
    isInvited: boolean
    isRead: boolean
  }
>(
  items: T[]
): T[] => {
  const readItems: T[] = []
  const unreadItems: T[] = []

  items.forEach(el => {
    if (el.isInvited) {
      readItems.push({ ...el, isRead: true })
    } else if (el.isRead) {
      readItems.push(el)
    } else {
      unreadItems.push(el)
    }
  })

  return [
    ...unreadItems.sort((a, b) => {
      const dateDiff = dayjs(a?.latestAt).diff(dayjs(b?.latestAt))

      return dateDiff < 0
        ? 1
        : dateDiff > 0
        ? -1
        : !a.isAdmin && b.isAdmin
        ? 1
        : a.isAdmin && !b.isAdmin
        ? -1
        : 0
    }),
    ...readItems.sort((a, b) => {
      const dateDiff = dayjs(a?.latestAt).diff(dayjs(b?.latestAt))

      return !a.isInvited && b.isInvited
        ? 1
        : a.isInvited && !b.isInvited
        ? -1
        : !a.isAdmin && b.isAdmin
        ? 1
        : a.isAdmin && !b.isAdmin
        ? -1
        : dateDiff < 0
        ? 1
        : dateDiff > 0
        ? -1
        : 0
    })
  ]
}

export const checkNetworkConnection = async (): Promise<boolean> => {
  const date = new Date()
  const timestamp = date.getTime()

  try {
    await fetch(`/favicon.ico?${timestamp}`)
  } catch {
    return false
  }
  return true
}

export const removeZeroPadding = (dateString: string): string => {
  return dateString.replace(/(\D)0+/g, '$1')
}

export const isValidColorCode = (colorCode: string): boolean => {
  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i
  const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/
  const rgbaRegex = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0\.\d*)\)$/

  return colorCode.startsWith('#')
    ? hexRegex.test(colorCode)
    : colorCode.startsWith('rgb(')
    ? rgbRegex.test(colorCode)
    : colorCode.startsWith('rgba(')
    ? rgbaRegex.test(colorCode)
    : false
}

export const convertKatakanaToHiragana = (str: string): string => {
  return str.replace(/[\u30A1-\u30FA]/g, function (match) {
    const chr = match.charCodeAt(0) - 0x60
    return String.fromCharCode(chr)
  })
}

const hasHalfWidthKatakana = (str: string): boolean => {
  const halfWidthKatakanaRegex = /[\uFF65-\uFF9F]/
  return halfWidthKatakanaRegex.test(str)
}

export const convertHalfWidthKatakanaToFullWidthKatakana = (
  str: string
): string => {
  if (!hasHalfWidthKatakana(str)) return str
  const kanaMap: { [key: string]: string } = {
    ｶﾞ: 'ガ',
    ｷﾞ: 'ギ',
    ｸﾞ: 'グ',
    ｹﾞ: 'ゲ',
    ｺﾞ: 'ゴ',
    ｻﾞ: 'ザ',
    ｼﾞ: 'ジ',
    ｽﾞ: 'ズ',
    ｾﾞ: 'ゼ',
    ｿﾞ: 'ゾ',
    ﾀﾞ: 'ダ',
    ﾁﾞ: 'ヂ',
    ﾂﾞ: 'ヅ',
    ﾃﾞ: 'デ',
    ﾄﾞ: 'ド',
    ﾊﾞ: 'バ',
    ﾋﾞ: 'ビ',
    ﾌﾞ: 'ブ',
    ﾍﾞ: 'ベ',
    ﾎﾞ: 'ボ',
    ﾊﾟ: 'パ',
    ﾋﾟ: 'ピ',
    ﾌﾟ: 'プ',
    ﾍﾟ: 'ペ',
    ﾎﾟ: 'ポ',
    ｱ: 'ア',
    ｲ: 'イ',
    ｳ: 'ウ',
    ｴ: 'エ',
    ｵ: 'オ',
    ｶ: 'カ',
    ｷ: 'キ',
    ｸ: 'ク',
    ｹ: 'ケ',
    ｺ: 'コ',
    ｻ: 'サ',
    ｼ: 'シ',
    ｽ: 'ス',
    ｾ: 'セ',
    ｿ: 'ソ',
    ﾀ: 'タ',
    ﾁ: 'チ',
    ﾂ: 'ツ',
    ﾃ: 'テ',
    ﾄ: 'ト',
    ﾅ: 'ナ',
    ﾆ: 'ニ',
    ﾇ: 'ヌ',
    ﾈ: 'ネ',
    ﾉ: 'ノ',
    ﾊ: 'ハ',
    ﾋ: 'ヒ',
    ﾌ: 'フ',
    ﾍ: 'ヘ',
    ﾎ: 'ホ',
    ﾏ: 'マ',
    ﾐ: 'ミ',
    ﾑ: 'ム',
    ﾒ: 'メ',
    ﾓ: 'モ',
    ﾔ: 'ヤ',
    ﾕ: 'ユ',
    ﾖ: 'ヨ',
    ﾗ: 'ラ',
    ﾘ: 'リ',
    ﾙ: 'ル',
    ﾚ: 'レ',
    ﾛ: 'ロ',
    ﾜ: 'ワ',
    ｦ: 'ヲ',
    ﾝ: 'ン',
    ｧ: 'ァ',
    ｨ: 'ィ',
    ｩ: 'ゥ',
    ｪ: 'ェ',
    ｫ: 'ォ',
    ｬ: 'ャ',
    ｭ: 'ュ',
    ｮ: 'ョ',
    ｯ: 'ッ',
    ｰ: 'ー'
  }

  let result = ''
  for (let i = 0; i < str.length; i++) {
    result += kanaMap[str[i] ?? ''] || str[i]
  }
  return result
}

export const calculateDoctorNenme = (
  certificationYear: string
): number | undefined => {
  const certificationYearNumber = Number(certificationYear)
  if (!certificationYear || !Number.isSafeInteger(certificationYearNumber))
    return undefined
  const nenme = dayjs().year() - certificationYearNumber + 1
  return nenme > 0 ? nenme : undefined
}

// ToDo: zodでvalidationする
export const isDoctorProfileCompletionRequired = (doctorProfile: {
  firstName?: string
  lastName?: string
  gender?: number
  hideGender?: boolean
  certificationYear?: string
  university?: string
  hideUniversity?: boolean
  doctorCategory?: Array<any>
  doctorExpertCategory?: Array<any>
  hospitals?: Array<any>
}): boolean => {
  const {
    firstName,
    lastName,
    gender,
    hideGender,
    certificationYear,
    university,
    hideUniversity,
    doctorCategory,
    doctorExpertCategory,
    hospitals
  } = doctorProfile
  return !(
    firstName &&
    lastName &&
    (hideGender || gender || gender === 0) &&
    certificationYear &&
    (hideUniversity || university) &&
    doctorCategory &&
    doctorCategory.length > 0 &&
    doctorExpertCategory &&
    doctorExpertCategory.length > 0 &&
    hospitals &&
    hospitals.length > 0
  )
}

export const doctorOrganization = (doctor: Doctor): string => {
  const hospital = doctor.hospitals?.[0]
  if (!hospital) return ''
  return `${hospital?.name ?? ''} (${hospital?.prefecture ?? ''}${
    hospital?.city ?? ''
  })`
}
export const msOrganization = (ms: MS): string => {
  return ms?.companyName && getMsSection(ms)
    ? `${ms.companyName}\u3000${getMsSection(ms)}`
    : ms?.companyName ?? ''
}

export const getMsSection = (ms: MS): string => {
  if (ms?.companyName === 'ドクシル事務局') {
    return ''
  }
  return (
    ms?.section?.replace(/\u3000/g, '\u0020') ??
    ms?.organization?.replace(/\u3000/g, '\u0020') ??
    ''
  )
}

export const getFullName = (
  user: MS | Doctor,
  includeHonorific: boolean = false
): string => {
  const honorific = isDoctor(user) ? ' 先生' : ' さん'
  if (user?.lastName && user?.firstName) {
    return (
      `${user.lastName} ${user.firstName}` + (includeHonorific ? honorific : '')
    )
  }
  if (user?.lastName) {
    return user.lastName + (includeHonorific ? honorific : '')
  }
  if (user?.firstName) {
    return user.firstName + (includeHonorific ? honorific : '')
  }
  return ''
}

export const getUserRole = (user: MS | Doctor): string => {
  return 'hpcrUser' in user ? 'doctor' : 'user' in user ? 'ms' : ''
}

export const isDoctor = (user: MS | Doctor): boolean => {
  return getUserRole(user) === 'doctor'
}

export const isMs = (user: MS | Doctor): boolean => {
  return getUserRole(user) === 'ms'
}

export const getLatestBusinessCard = <T extends { createdAt: string }>(
  array: T[] | undefined
): T | undefined => {
  if (!array) return undefined
  return array?.length > 0
    ? array.reduce((latest: T, item: T) => {
        return dayjs(latest.createdAt).isAfter(dayjs(item.createdAt))
          ? latest
          : item
      })
    : undefined
}

export const getUserObjectId = (user: MS | Doctor): string => {
  return 'hpcrUser' in user
    ? user.hpcrUser?.objectId || ''
    : 'user' in user
    ? user.user.objectId
    : ''
}

export const decodeUserIdWithRole = (
  id: string,
  isOnlyObjectId: boolean = false
):
  | string
  | {
      objectId?: string
      role?: string
    } => {
  const delimiter = USER_ID_WITH_ROLE_DELIMITER
  if (!id.includes(delimiter)) return id
  const [objectId, role] = id.split(delimiter)
  if (isOnlyObjectId) return objectId ?? ''
  return {
    objectId,
    role: role === 'dcsr' ? 'doctor' : role
  }
}

export const encodeUserIdWithRole = (
  objectId: string,
  role: 'doctor' | 'ms'
): string => {
  const delimiter = USER_ID_WITH_ROLE_DELIMITER
  if (objectId.includes(delimiter)) return objectId
  return `${objectId}${delimiter}${role === 'doctor' ? 'dcsr' : role}`
}

export const isMessageRoleDoctor = (objectId: string): boolean => {
  return objectId.endsWith('dcsr')
}

export const isMessageRoleMs = (objectId: string): boolean => {
  return objectId.endsWith('ms')
}

export const removeSpaces = (str: string): string => {
  return str.replace(/[\s\uFEFF\xA0\u2000-\u200B\u2028\u2029\u3000]+/g, '')
}

export const generateSequentialArray = (length: number): number[] => {
  return length > 0 ? Array.from({ length }, (_, i) => i) : []
}

export const removeKeysFromObject = (
  obj: { [key: string]: any },
  keysToRemove: string[]
): { [key: string]: any } => {
  return Object.keys(obj)
    .filter(key => !keysToRemove.includes(key))
    .reduce((newObj: { [key: string]: any }, key: string) => {
      newObj[key] = obj[key]
      return newObj
    }, {})
}

export const isViewedUser = (
  value?: number | string[],
  userId?: string
): boolean => {
  const viewedUserIds = Array.isArray(value) ? value : []
  return viewedUserIds?.includes(userId ?? '') ?? false
}

export const updateQueryStringParameter = (
  uri: string,
  key: string,
  value: string
): string => {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  const separator = uri.includes('?') ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`)
  } else {
    return `${uri}${separator}${key}=${value}`
  }
}

export const extractErrorMessage = (error: any): string => {
  return (
    error?.response?.data?.message ||
    error?.response?.data ||
    error?.message ||
    error ||
    ''
  )
}

export const addDays = (date: Date, days: number) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const daysRemaining = (createdAt: Date) => {
  const currentDate = new Date()
  // 現在の日付の時間部分をリセット（00:00:00にする）
  const currentDateWithoutTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  )

  // createdAtの日付部分をリセット（00:00:00にする）
  const createdAtDate = new Date(createdAt)
  const createdAtWithoutTime = new Date(
    createdAtDate.getFullYear(),
    createdAtDate.getMonth(),
    createdAtDate.getDate()
  )

  // 作成日から30日後の日付を計算
  const expirationDate = new Date(createdAtWithoutTime)
  expirationDate.setDate(createdAtWithoutTime.getDate() + 29)

  // 日付の差を計算
  const timeDifference =
    expirationDate.getTime() - currentDateWithoutTime.getTime()

  // 日数に変換
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
}

export const sortTerritories = (territories: Territory[]): Territory[] => {
  const prefectureCodeToNumber = (prefectureCode: string): number =>
    parseInt(prefectureCode, 10)

  return territories.sort((a, b) => {
    return (
      prefectureCodeToNumber(a.prefectureCode) -
      prefectureCodeToNumber(b.prefectureCode)
    )
  })
}

export const isValidFileTypes = (file: File, validTypes: string[]): boolean => {
  const fileType = file.type
  return validTypes.includes(fileType)
}

export const convertToObject = (
  input: { key: string; value: boolean } | { key: string; value: boolean }[]
): { [key: string]: boolean } => {
  if (Array.isArray(input)) {
    return input.reduce((acc: { [key: string]: boolean }, obj) => {
      acc[obj.key] = obj.value
      return acc
    }, {})
  } else {
    return { [input.key]: input.value }
  }
}

export interface EmojiWithReaction extends Emoji {
  isReacted: boolean
  count?: number
  name: string
}

export const convertEmojiList = (
  reactions: Reaction[],
  emojiList: Emoji[]
): EmojiWithReaction[] =>
  emojiList
    .map((emoji: Emoji) => {
      const reaction = reactions.find((r: Reaction) => r.name === emoji.name)
      return reaction ? { ...emoji, ...reaction } : null
    })
    .filter((emoji): emoji is EmojiWithReaction => emoji !== null)

export const converTerritoriesToString = (territories: Territory[]): string => {
  if (!territories?.length) return ''
  return territories
    .map(item => item?.prefecture)
    .filter(Boolean)
    .join(',')
}
