import constant from '~/helper/constant'

export const getTopPath = (role: string): string => {
  if (role?.toLowerCase() === constant.roles.doctor) {
    return '/'
  }

  if (role?.toLowerCase() === constant.roles.coMedical) {
    return '/top/medical'
  }

  if (role?.toLowerCase() === constant.roles.ms) {
    return '/top/ms'
  }

  if (role?.toLowerCase() === constant.roles.connect) {
    return '/connect/top'
  }

  return '/'
}
