import { Middleware } from '@nuxt/types'
import config from '~/helper/constant'

const middleware: Middleware = ({ store, redirect, route, app }) => {
  if (!store.state.currentUser) {
    app.$cookies.set(config.redirectKey, route.fullPath)
    redirect('/connect/login')
  }
}

export default middleware
